import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory} from 'vue-router'
import { AssetLoader, RAFCollection } from './assets/js/utils'
import GlobalEvents from './assets/js/utils/GlobalEvents'
import store from './assets/js/store'


// import PageHolder from '../src/pages/PageHolder'
import About from '../src/pages/About'
import Contact from '../src/pages/Contact'
import Ops from '../src/pages/Ops'
import Home from '../src/pages/Home'
// import Countdown from '../src/pages/Countdown'
import NotFound from '../src/pages/NotFound'



const routes = [
	{
		path: '/',
		component: Home,
		name: 'Home',
		meta: { 
			transition: 'rotate', 
			title: 'GroomLake',
			metaTags: [
				{
					name: 'description',
					content: 'Home'
				},
				{
					name: 'og:description',
					content: 'Home'
				}
			]
		}
	}
	,
	{
		path: '/about',
		component: About,
		name: 'About',
		meta: { 
			transition: 'rotate', 
			title: 'GroomLake - About',
			metaTags: [
				{
					name: 'description',
					content: 'About'
				},
				{
					name: 'og:description',
					content: 'About'
				}
			]
		}
	},
	// {
	// 	path: '/countdown',
	// 	component: Countdown,
	// 	name: 'Countdown',
	// 	meta: { 
	// 		transition: 'rotate', 
	// 		title: 'GroomLake - Countdown'
	// 	}
	// },
	{
		path: '/activate',
		component: Contact,
		name: 'Contact',
		meta: { 
			transition: 'rotate', 
			title: 'GroomLake - Activate',
			metaTags: [
				{
					name: 'description',
					content: 'Activate'
				},
				{
					name: 'og:description',
					content: 'Activate'
				}
			]
		}
	},
	{
		path: '/ops',
		component: Ops,
		name: 'Ops',
		meta: { 
			transition: 'rotate', 
			title: 'GroomLake - Ops',
			metaTags: [
				{
					name: 'description',
					content: 'Ops'
				},
				{
					name: 'og:description',
					content: 'Ops'
				}
			]
		}
	},
	{	
		path: '/:notFound',
		component: NotFound,
		name: '404-page',
		meta: { transition: 'fade'}
	},
]	

const router = createRouter({
	history: createWebHistory(),
	routes,
})

store.RAFCollection = new RAFCollection()
store.AssetLoader = new AssetLoader()

window.urlParams = new URLSearchParams(window.location.search)

GlobalEvents.detectTouchDevice()
GlobalEvents.enableRAF(true)
GlobalEvents.enableResize()
GlobalEvents.enableMousemove()

createApp(App).use(router).mount('#app')
