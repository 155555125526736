<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="basic-component compEl about">
		<!-- <PageLayout v-if="!isMobile" :altitude="0"/> -->
		<Menu />

		<svg xmlns='http://www.w3.org/2000/svg' class="maskSvg" v-if="isFireFox">
			<filter id="filter">
				<feGaussianBlur stdDeviation="30" />
			</filter>

			<mask id="blurrMask">
				<rect x='0' y='0' rx='0' width="100vw" height="(var(--vh, 1vh) * 100);" stroke="blue" fill="black" />
				<rect x='7.5vw' y='15vh' rx='50' width="85vw" height="70vh" filter="url(#filter)" fill="white" />
			</mask>

		</svg>
		<svg xmlns='http://www.w3.org/2000/svg' class="maskSvg" v-if="isFireFox">
			<filter id="filter">
				<feGaussianBlur stdDeviation="30" />
			</filter>

			<mask id="blurrMaskInv">
				<rect x='0' y='0' rx='0' width="100vw" height="100vh" stroke="blue" fill="white" />
				<rect x='7.5vw' y='15vh' rx='50' width="85vw" height="70vh" filter="url(#filter)" fill="black" />
			</mask>

		</svg>
		<!-- <svg viewBox="0 0 100 100" class="svgBlobs" v-if="!isSafari">
			<path :d="path" fill="white"></path>
		</svg> -->
		<div class="content">

			<div class="mask-container" ref="layer">
				<div class="blur"></div>
				<div class="content--inner" ref="inner"
					:style="`transform: translate3d(calc(${this.isMobile ? '0' : '-50%'} + ${-pageTransform.x * (deltaX + 200)}px), ${Math.max(1 - (pageTransform.y + 1), 0) * -(deltaY + 200)}px, 0 )`">
					<div class="left-part" ref="left">
						<BraketComp v-for="(item, i) in left" :key="i" :data="item" />
					</div>
					<div class="center-part">
						<div class="container">
							<h1>
								<span class='caption'>/.3</span>
								<span class='caption'>About</span>
							</h1>
							<div class="head-text" ref="head">
								<ScrambleComp :val='center.scrambled' :appearTime="0.1"
									ref="scramble" :afterAppear="switchHead" />
								<div class="real" ref="real" v-html='center.realScramble'></div>
							</div>
							<div class="second-text">
								{{ center.second }}
							</div>
							<div class="separator">- - - -</div>
							<div class="third-text">
								{{ center.third }}
							</div>

							<div class="number">
								<span class='caption'>/.4</span>
							</div>
						</div>


					</div>
					<div class="right-part">
						<BraketComp v-for="(item, i) in right" :key="i" :data="item" />
					</div>
				</div>
			</div>

		</div>
		<div class="bottom-part">
			<span class="toWrite" v-html="unclassified"></span>
			<div class="right">
				<img :src="logo" alt="">
				<div class="coordinates toWrite" v-if="!isMobile">
					<span>{{ (cursorX * 10).toFixed(2) }} -BKD</span>
					<span>{{ (cursorY * 10).toFixed(2) }}°ST</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Menu from '@/components/Menu';
import blobshape from "blobshape";
import store from "@/assets/js/store";
// import PageLayout from '@/components/PageLayout'
import ScrambleComp from '@/components/ScrambleComp'
import BraketComp from '@/components/bracketText'
import { Vector2 } from "three";
import E from '@/assets/js/utils/E'
import GlobalEvents from '@/assets/js/utils/GlobalEvents'
import gsap from 'gsap'
// import ScrollTrigger from 'gsap/ScrollTrigger'
import logo from '@/assets/images/logo.png'
import SplitType from 'split-type'

// gsap.registerPlugin(ScrollTrigger)
export default {
  name: 'Basic-comp',
  components: {
	ScrambleComp,
	// PageLayout,
	BraketComp,
	Menu
  },
  props: {
    data: Object
  },
  data () {
    return {
		path: '',
		logo: logo,
		isSafari: store.isSafari,
		isFireFox: store.isFireFox,
		isMobile: store.isMobile || store.window.w < 813,
		pageTransform: new Vector2(),
		deltaY: 0,
		deltaX: 0,
		dampVal: 0,
		cursorX: 0.4,
		cursorY: -0.75,
		unclassified: 'Top Secret//SI//Rel to glpmc, fvey',
		center: {
			scrambled: "The private military corporation for Web3, Groom lake combines advanced intelligence capabilities with state-of-the-art security operations.",
			realScramble: "The private military corporation for Web3, <span class='highlight'><span>Groom lake</span></span>	combines advanced intelligence capabilities with state-of-the-art security operations.",
			second: "Groom Lake's foundational service offering equips you with a dedicated Fire Team armed with proprietary tooling. Our Fire Team operatives bring deep expertise in Offensive Security, Penetration Testing, Operational Security, Monitoring & Alerting, Web Application Security, Incident Response, and more.",
			third: "As an official partner of the US Department of Defense’s servicemember transition programs, the team is composed of trained intelligence and cybersecurity operators transitioning from combating nation-state threats to securing protocols and teams like yours."
		},
		left:[
			{
				title: 'value of DeFi companies we currently secure',
				val: 'Over $12 billion'
			},
			{
				title: 'exploit response & recovery supported',
				val: 'Over $550 million'
			}
		],
		right:[
			{
				title: 'total deployments/major operations conducted in last year',
				val: '24'
			},
			{
				title: 'combined experience in cybersecurity and intelligence',
				val: '40 Years'
			}
			
		]

	}
  },
  created () {
	if(!this.isSafari){
		let path = blobshape({ size: 100, growth: 1, edges: 10, seed: null });
		this.path = path.path
	}
  },
  beforeUnmount(){
	E.off('transitionEnd', this.setup)
	E.off(GlobalEvents.RESIZE, this.onResize)
	store.RAFCollection.remove(this.onRaf, 0)
	this.trigger?.kill()
  },
  mounted () {
	E.on('transitionEnd', this.setup)
	this.$refs.real.split = new  SplitType(this.$refs.real, { types: 'lines, words'})
	if(!this.isMobile){
		this.deltaY = this.$refs.inner.getBoundingClientRect().height - store.window.h
		this.deltaX = this.$refs.left.getBoundingClientRect().width
		
		
	} 
	store.RAFCollection.add(this.onRaf, 0)
	E.on(GlobalEvents.RESIZE, this.onResize)
	
	
  },
  methods: {
	setup(){
		this.$refs.scramble.decypher('in')
		if(!this.isMobile){
			this.deltaY = this.$refs.inner.getBoundingClientRect().height - store.window.h
			this.deltaX = this.$refs.left.getBoundingClientRect().width
		}
	},
	switchHead(){
		this.$refs.head.classList.add('show')
		gsap.to(this, {dampVal: 0.1, delay: 1, duration: 1})
	},
	onRaf(){
		// console.log(store.pointer.glNormalized)
		let v3 = new Vector2()

		v3.copy(store.pointer.glNormalized)
		v3.y = Math.min(v3.y, 0)
		v3.sub(this.pageTransform)
		v3.multiplyScalar(this.dampVal)
		this.pageTransform.add(v3)
		// console.log(Math.max(1 - (pageTransform.y + 1), 0) * deltaY)
		console.log('coucou', this.dampVal, this.isMobile)
		this.cursorX = store.pointer.glNormalized.x
		this.cursorY = store.pointer.glNormalized.y
	},
	onResize(){
		this.deltaY = this.$refs.inner.getBoundingClientRect().height - store.window.h
		this.$refs.real.split = new  SplitType(this.$refs.real, { types: 'lines, words'})
		this.isMobile = store.isMobile || store.window.w < 813
		this.$refs.layer.scrollTo(0, 0)
		this.dampVal = 0.1

		if(!this.isMobile){
			this.deltaY = this.$refs.inner.getBoundingClientRect().height - store.window.h
			this.deltaX = this.$refs.left.getBoundingClientRect().width
			E.on(GlobalEvents.RESIZE, this.onResize)
			store.RAFCollection.add(this.onRaf, 0)
		} else {
			// this.trigger = ScrollTrigger.create({
			// 	trigger: this.$refs.inner,
			// 	scroller:  this.$refs.layer,
			// 	start: 'top top',
			// 	end: 'bottom top',
			// 	onUpdate: (self) => {
			// 		console.log('COUCOU')
			// 		this.$refs.inner[0].style.transform = `translate3d(-50%, ${-self.progress * self.end}px, 0)`
			// 	}
			// });
		}
	
	}
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>