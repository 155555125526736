<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="basic-component compEl home">
		<PageLayout v-if="!isMobile" :secondStep="isSecondStep" :altitude="blur" />
		<svg viewBox="0 0 100 100" class="svgBlobs" v-if="!isSafari">
				<path :d="path" fill="white"></path>
			</svg>
		<div class="content" ref='content'>
			

			<div class="content--inner" ref="inner">
				<div class="fixed-part">
					<transition name="fade">
						<div v-if="isFirstStep">
							<h1 @mouseenter="decypher" @mouseleave="cypher" ref="request" >
								<ScrambleComp :val='access' :appearTime="0.1" ref="scramble" />
							</h1>
							<div class="scrollCta" :class="{ 'show' : scrollIsAppear }">
								<div class="target-container">
									<div class="target"></div>
									<span class="dash" v-for="dash in 10" ref="dash" :key="dash"></span>
								</div>
								<span class="cta-title" ref="scrollText">scroll</span>
							</div>
						</div>

					</transition>
					<transition name="fade">
						<div class="logo-container" v-if="!isFirstStep && !isSecondStep">
							<img :src="logo" alt="">
						</div>
					</transition>
					<transition name="fade">
						<div class="endScreen" v-if="isSecondStep">
							<h1 ref="title">
								<ScrambleComp :val='title' ref="scrambleTitle" :appearTime="0.03" />
							</h1>
							<Menu :home="true" ref="menu" />

						</div>

					</transition>

					<div class="bottom-part">
						<span class="toWrite" v-html="isFirstStep ? classified : unclassified"></span>
						<div class="right">
							<img :src="logo" alt="">
							<div class="coordinates toWrite" v-if="!isMobile">
								<span>{{ (cursorX * 10).toFixed(2)}} -BKD</span>
								<span>{{ (cursorY * 10).toFixed(2)}}°ST</span>
							</div>
						</div>
					</div>
					<div class="top-part">
						<span>></span>
						<div class="coordinates toWrite">
							<span>25010 B</span>
							<span>DCLTR</span>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
import ScrambleComp from '@/components/ScrambleComp'
import PageLayout from '@/components/PageLayout'
import Menu from '@/components/Menu'
import logo from '@/assets/images/logo.png'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsap from 'gsap'
import blobshape from "blobshape";
import SplitType from 'split-type'
import store from '@/assets/js/store'
import E from '@/assets/js/utils/E'
gsap.registerPlugin(ScrollTrigger)
gsap.config({
  force3D: true,
})

export default {
  name: 'Home-comp',
  components: {
	ScrambleComp,
	PageLayout,
	Menu
  },
  props: {
    data: Object
  },
  data () {
    return {
		access: 'Request Access',
		title: 'The private military corporation of web3',
		classified: "Controlled Unclassified Information",
		unclassified: "Top secret//Rel to glpmc, fvey",
		logo: logo,
		animationLength: store.isMobile ? '200vh' : '500vh',
		blur: 0,
		bright: 0,
		isFirstStep: !store.hasDoneLanding,
		isSecondStep: store.hasDoneLanding,
		path: '',
		isMobile: store.isMobile,
		isSafari: store.isSafari,
		scrollIsAppear: false,
		cursor: store.pointer.glNormalized,
		cursorX: 0.4,
		cursorY: -0.75
	}
  },
  created () {
  },
  mounted () {

	E.on('transitionEnd', this.setup)
	if(!this.isSafari){
		let path = blobshape({ size: 100, growth: 1, edges: 10, seed: null });
		this.path = path.path
	}
	this.setWrite(Array.from(this.$el.querySelectorAll('.toWrite')))
	if(!this.isSecondStep){
		this.setDash()
		setTimeout(() => {
			this.$refs.scramble.appear()
		})
	}
	store.RAFCollection.add(this.onRaf, 0)
  },
  beforeUnmount (){
	this.timeline?.kill()
	E.off('transitionEnd', this.setup)
	store.RAFCollection.remove(this.onRaf, 0)

  },
  methods: {
	setup(){
		if(this.isSecondStep){
			if(this.$refs.scrambleTitle) this.$refs.scrambleTitle.appear()
			this.$refs.menu.open = false
			this.$refs.menu.toggle()
			this.$refs.scrambleTitle.decypher('in')
		}
	},
	setDash(){
		this.$refs.scrollText.split = new SplitType(this.$refs.scrollText, {types: 'char'})
		this.$refs.scrollText.split.chars.forEach((el, i) => {
			el.style.transitionDelay = i * 0.1 + 's'
		})
		this.$refs.dash.forEach((el, i) => {
			el.transformInit = i * 40
			el.style.transform = `translate3d(-50%, calc(${el.transformInit}px - 50%), 0)`
			el.style.setProperty('--dashA', 1 - Math.max(0, Math.abs(el.transformInit) - 100) * 0.01)
			// el.style.opacity = 1 - Math.max(0, Math.abs(el.transformInit) - 100) * 0.01
		})
	},
	setWrite(arr) {
		arr.forEach(el => {
			el.split = new SplitType(el, {types: 'char, word'})
			gsap.set(el.split.chars, {opacity: 0})
			gsap.to(el.split.chars, {opacity: 1, delay: 0.5, duration: 0.001, stagger: 1 / el.split.chars.length})
		});
	},
	decypher(){
		if(!this.scrollIsAppear){
			gsap.from(this.$refs.dash, { y: '+='+30, stagger: 0.05})
		}
		this.scrollIsAppear = true
		// gsap.to(this.$refs.scrollText.split.chars, {opacity: 1, delay: 0.5, duration: 0.001, stagger:0.1})
		// this.$refs.request.style.pointerEvents = 'none'
		if(!this.timeline){
			this.$refs.scramble.decypher('in')
			this.$refs.inner.style.height = this.animationLength
			setTimeout(() => {
				if(!this.timeline) 	this.setScrollTrigger()
			}, 500)
		}
	},
	setScrollTrigger(){
		this.dpr = window.devicePixelRatio
		this.timeline = gsap.timeline({
		// yes, we can add it to an entire timeline!
			paused: true,
			scrollTrigger: {
				scroller: this.$refs.content,
				trigger: this.$refs.inner,
				// pin: '.fixed-part',
				start: 'top top', // when the top of the trigger hits the top of the viewport
				end: 'bottom bottom', // end after scrolling 500px beyond the start
				scrub:0, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
			}
		})
		.to(this, {
			blur: this.isMobile ? 10 : 30,
			onUpdate: () => {
				this.$el.style.setProperty('--blur', this.blur / this.dpr)
				this.$refs.dash.forEach((el) => {
					el.style.transform = `translate3d(-50%, calc(${el.transformInit - this.blur * 10}px - 50%), 0)`
					el.style.opacity = 1 - Math.max(0, Math.abs(el.transformInit - this.blur * 10) - 100) * 0.01
				})
			}
		})
		.call(() => {
			this.isFirstStep = !this.isFirstStep
			
			this.$nextTick(() => {
				if(this.$refs.scramble) {
					this.$refs.scramble.appear()
					this.$refs.scramble.decypher('in')
				}
				if(this.isFirstStep){
					this.setDash()
				}
			})
		})
		.to(this, {
			blur: this.isMobile ? 5 : 10,
			onUpdate: () => {
				this.$el.style.setProperty('--blur', this.blur / this.dpr)
			},
		})
		.to(this, {
			blur: this.isMobile ? 10 : 30,
			onUpdate: () => {
				this.$el.style.setProperty('--blur', this.blur / this.dpr)
			},
		})
		.call(() => {
			this.isSecondStep = !this.isSecondStep
			store.hasDoneLanding = true
			console.log(this.isSecondStep)
			this.$nextTick(() => {
				if(this.$refs.scrambleTitle) this.$refs.scrambleTitle.appear()
			})
		})
		.to(this, {
			blur: this.isMobile ? 7 : 20,
			onUpdate: () => {
				this.$el.style.setProperty('--blur', this.blur / this.dpr)
			},
		})
		.to(this, {
			blur: 0,
			onUpdate: () => {
				this.$el.style.setProperty('--blur', this.blur / this.dpr)
			},
		})
		.call(() => {
			if(this.$refs.menu) this.$refs.menu.open = false
			if(this.$refs.menu) this.$refs.menu?.toggle()
			this.$refs.scrambleTitle?.decypher('in')
			this.$el.style.cursor = 'default'
		})
		// this.brightTimeline = gsap.timeline({paused: true})
		// this.brightTimeline.to(this, {
		// 	bright: 1,
		// 	ease: "rough({template:none.out,strength: 1,points:20,taper:none,randomize:true,clamp:true})",
		// 	onUpdate: () => {
				// this.$el.style.setProperty('--blur', this.blur / this.dpr)
		// 	}
		// })
	},
	cypher(){
		// this.$refs.scramble.decypher('out')
	},
	onRaf(){
		this.cursorX = store.pointer.glNormalized.x
		this.cursorY = store.pointer.glNormalized.y
	}
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>